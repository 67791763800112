/* You can add global styles to this file, and also import other style files */

@import url("../node_modules/primeicons/primeicons.css");
@import url("../node_modules/primeng/resources/themes/lara-light-purple/theme.css");
@import url("../node_modules/primeng/resources/primeng.min.css");
@import url("../node_modules/primeflex/primeflex.css");
@import url("../node_modules/ngx-spinner/animations/ball-atom.css");
@import url("../node_modules/ngx-spinner/animations/ball-spin-clockwise-fade.css");

.p-menu {
    padding: 0.25rem 0;
    background: #ffffff;
    color: #495057;
    border: none !important;
    border-radius: 6px;
    width: 100% !important;
  }

.p-menuitem-link-active{
  background-color: #dddddd;
}

.tborder {
  border-collapse: collapse;
  border: 0px;
}

@media print {
  #noprint {
    visibility: hidden;
  }
}